import styled from "styled-components"
import LogoIcon from "../assets/logo.svg"
import { Link, NavLink, useLocation } from "react-router-dom"

import BlogIcon from "../assets/blog.svg"
import DiscusIcon from "../assets/discuss.svg"
import VideoIcon from "../assets/video.svg"
import ArrowIcon from "../assets/arrow.svg"
import PlusIcon from "../assets/plus.svg"
import { useState } from "react"

const Sidebar = () => {
    const navLinks = [
        { icon: BlogIcon, title: "Blogs", url: "/blogs" },
        { icon: DiscusIcon, title: "Discussions", url: "/discussions" },
        { icon: VideoIcon, title: "Courses", url: "/courses" },
    ]

    const { pathname } = useLocation()

    return (
        <SidebarContainer>
            <LogoContainer>
                <img src={LogoIcon} alt="" />
            </LogoContainer>

            <NavLinks>
                {navLinks.map(({ icon, title, url }) => {
                    return (
                        <StyledNavLink to={url}>
                            <img src={icon} alt="" />
                            <span>{title}</span>
                            {title === "Blogs" && pathname === "/blogs" ?
                                <Link to="/blogs/create" className="arrow-icon">
                                    <img src={PlusIcon} alt="" />
                                </Link>
                                :
                                <img className="arrow-icon" src={ArrowIcon} alt="" />}
                        </StyledNavLink>
                    )
                })}
            </NavLinks>
        </SidebarContainer>
    )
}

export default Sidebar;

const SidebarContainer = styled.div`
    width: 100%;
    padding: 30px 0;
`;

const LogoContainer = styled.div`
    
`;

const NavLinks = styled.div`
    margin-top: 20px;
`;

const StyledNavLink = styled(NavLink)`
    padding: 20px 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;
    color: white;
    position: relative;

    &.active {
        background: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
    }

    .arrow-icon {
        position: absolute;
        right: 10px;
    }
`;