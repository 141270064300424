import styled from "styled-components"
import Blog from "../components/Blog";

export const blogs = [
    { id: 1, title: "HTML", paragraph: "HTML is the structure of web page!" },
    { id: 2, title: "JavaScript", paragraph: "JavaScript is the logic of web page!" },
    { id: 3, title: "CSS", paragraph: "CSS is the beauty of web page!" },
]

const Blogs = () => {
    return (
        <BlogsContainer>
            {blogs.map(({ title, paragraph, id }) => <Blog key={id} id={id} title={title} paragraph={paragraph} />)}
        </BlogsContainer>
    )
}

export default Blogs;

const BlogsContainer = styled.div`

`;