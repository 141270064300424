import styled from "styled-components";
import { useNavigate } from "react-router-dom"

const BlogCreate = () => {
    const navigate = useNavigate();

    return (
        <Wrapper>

            <button onClick={() => navigate(-1)}>{`<- Back`}</button>
            <input type="text" name="" id="" />

            <textarea name="" id="" cols="30" rows="10"></textarea>

            <button>Create</button>
        </Wrapper>
    )
}

export default BlogCreate;

const Wrapper = styled.div`
    padding: 50px;
    width: 500px;
    
    * {
        display: block;
        width: 100%;
        margin: 20px 0;
        padding: 20px;
    }

    button {
        font-size: 18px;
    }
`;

